@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}



.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.slide-in{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height:100vh;
    background: #F1CEDD;
  transform-origin: bottom;
}
.slide-out{
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background: #F1CEDD;
    transform-origin: top;
}


/* height of the container */
.leaflet-container {
  height: 90vh;
  width: 100%;
  border-radius: 2rem;
}

.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}


/* styles.css */
.scrollbar-custom {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: red transparent; /* Firefox */
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: red; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid transparent; /* Border around the scrollbar thumb */
  background-clip: content-box; /* Ensures the border appears correctly */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: red; /* Darker color on hover */
}
